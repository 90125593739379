@import url("https://fonts.googleapis.com/css?family=Yellowtail&display=swap");

.base {
  overflow: hidden;
}
.base .hero-slide {
  position: relative;
  background: url("./assets/friends.jpg");
  background-color: rgba(1, 245, 227, 0.6);
  background-size: cover;
  min-height: 30vh;
  padding: 2.2rem 0 9rem;
}

@media (max-width: 654px) {
  .base .hero-slide {
    padding: 5rem 1rem;
  }

  .base .hero-slide h1 {
    display: none;
  }
}

.base .video-wrapper {
  max-width: 800px;
  margin: 0 auto;
  position: relative;
  border: 15px solid rgb(1, 245, 227);
}

.base .video-overlay {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  background-color: rgba(1, 245, 227, 0.5);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 3;
  transition: 200ms;
}
.base .video-overlay:hover {
  background-color: rgba(1, 245, 227, 0.6);
}

.base .video-overlay.hide {
  display: none;
}

.base .video-overlay svg {
  font-size: 7rem;
}

.base .hero-slide video {
  max-width: 100%;
  margin-bottom: -4px;
  box-sizing: border-box;
}
.base .hero-footer {
  max-width: 700px;
}

.base h1 {
  display: inline-block;
  width: 100%;
  text-align: center;
  font-size: 4rem;
  font-family: "Yellowtail", "cursive";
  color: rgba(255, 255, 255, 1);
  position: absolute;
  bottom: 106px;
  left: 10%;
  z-index: 3;
  text-shadow: 2px 2px 1px rgba(0, 0, 0, 0.2);
  pointer-events: none;
}

@media (max-width: 654px) {
  .base h1 {
    display: none;
  }
}

.base .hero-box {
  color: white;
  background: rgba(29, 176, 215, 0.9);
  max-width: 700px;
  margin: 0 auto;
  padding: 40px;
  position: relative;
  top: -80px;
  left: 0;
}

.base .hero-box:after {
  content: "";
  position: absolute;
  bottom: 0;
  right: 10%;
  width: 0;
  height: 0;
  border: 40px solid transparent;
  border-top-color: rgba(29, 176, 215, 0.9);
  border-bottom: 0;
  border-right: 0;
  margin-left: -10px;
  margin-bottom: -40px;
}

.base .hero-box h2 {
  color: white;
  margin: 0;
  font-size: 1.5rem;
  font-weight: normal;
  padding-bottom: 20px;
}

.base .hero-box h3 {
  color: white;
  font-size: 2rem;
  padding-bottom: 20px;
}

.base .hero-box h4 {
  color: white;
  font-size: 1.4rem;
}

.base .buttons {
  display: flex;
  position: absolute;
  bottom: 40px;
  right: 40px;
}

@media (max-width: 654px) {
  .base .buttons {
    position: relative;
    bottom: -1rem;
    margin-bottom: 2rem;
  }

  .base .hero-footer {
    padding: 1rem;
    font-size: 0.8rem;
  }
}

.base .buttons button {
  color: white;
  font-size: 1rem;
  border: 2px solid white;
  background: transparent;
}

.base .hero-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 760px;
  margin: -20px auto 0;
  position: relative;
}

.base .hero-footer img {
  max-width: 190px;
}

.base .hero-footer .arrow {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 5rem;
  color: white;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: #1db0d7;
  position: absolute;
  top: 120px;
  left: 50%;
  margin-left: -30px;
}
