
.base {

    background-color: #edf4cc;
    padding: 80px 50px 180px;
}
.base .container {
    display: flex;
    align-items: center;
    flex-direction: column;
    max-width: 1000px;
    margin: 100px auto 0;
}

.base img {
    margin: 80px 0;
    max-width: 100%;
}

.base p {
    color: #777;
    font-size: 1.2rem;
    line-height: 2.1rem;
    text-align: center;
    letter-spacing: 0.5px;
    max-width: 600px;
}

.base h1 {
    color: #777;
}

.base a {
    text-decoration: none;
    color: #168fab;
    border-bottom: 2px solid transparent;
}

.base a:hover {
    border-bottom-color: #168fab;
}