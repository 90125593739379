.base {
    background: #00B0D9;
    padding: 80px 40px; /* Just for testiing purposes*/
}

.base h1  {
    color: white;
    text-align: right;
}

.base h2 {
    color: white;
    text-align: right;
    margin-top: 3rem;
}

.base p {
    color: white;
    text-align: right;
}

.base .flex-columns {
    display: flex;
    align-items: center;
    justify-content: center;
}

@media (max-width: 654px) {
    .base .flex-columns {
        align-items: center;
        justify-content: center;
        flex-direction: column-reverse;
    }
}

.base .flex-columns > div {
    text-align: right;
    color: white;
}

.base img {
    max-width: 500px;
    margin: 4rem;
    border: 15px solid #168fab;
}

@media (max-width: 654px) {
    .base img {
        max-width: 80%;
    }
}

.base a {
    display: inline-block;
    line-height: 1.6rem;
    text-align: right;
    text-decoration: none;
    color: white;
    font-weight: bold;
    border-bottom: 2px solid #BCCF41;
}

.base a:last-child {
    margin-top: 0.9rem;
}