.base { 
    /* background-color: #192f4c; */
    padding: 4rem 0;
    overflow: hidden;
}

.base .container {
    /* max-width: 1000px; */
    margin: 0 auto;
}

.base h3 {
    margin-top: 2rem;
}
.base h4 {
    margin-top: 1.2rem;
}
.base section {
    margin: 3rem 0;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.base div {
    width: 100%;
}

.base img {
    width: 100%;
    height: 400px;
    object-fit: cover;
}

@media (max-width: 680px) {
    .base img {
        height: 250px;
    }
}
