@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,900&display=swap');

* {
    font-family: 'Roboto', sans-serif;
}

body {
    margin: 0;
}

h1, h2, h3, h4, h5, h6 {
    margin: 0;
    color: #777;
}
h1 { font-size: 3rem; }
h2 { font-size: 1.75rem; }
h3 { font-size: 1.5rem; }

img {
    width: 100%;
}

p {
    color: #777;
    font-size: 1.0rem;
    line-height: 1.6rem;
    letter-spacing: 0.3px;
    margin: 1em auto;
}