.stichting {
  max-width: 1224px;
  margin-inline: auto;
}

.stichting,
.stichting h1,
.stichting h2,
.stichting h3,
.stichting p {
  color: #234;
}

h1,
h2 {
  margin-top: 1rem;
}

.logo {
  width: min(400px, 100%);
}

section {
  margin-inline: 16px;
  max-width: prose;
}

th {
  text-align: left;
  padding-right: 4px;
}

.acronym > span {
  font-weight: bold;
}

.board {
  margin-top: 2rem;
  display: flex;
  flex-wrap: wrap;
}

.member {
  margin-inline: auto;
}

.member > img {
  max-width: 300px;
  border-radius: 8px;
  margin-bottom: 8px;
}

.member > p {
  margin: 0;
}