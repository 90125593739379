.base {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-weight: bold;
    background: #1DB0D7;
    border-radius: 5px;
    color: white;
    padding: 10px;
    margin-left: 1rem;
    border-bottom: none;
    box-shadow: 0 3px #1698b4;
    transition: 100ms;
    text-decoration: none;
}

.base.red {
    background-color: rgb(214, 90, 90);
}
.base.red:hover {
    background-color: rgb(195, 77, 77);
}

.base.big {
    padding: 1rem;
}

.base:hover {
    background: #168fab;
    box-shadow: 0 0 #168fab;
    border-bottom: none;
}

.base.disabled {
    cursor: not-allowed;
}

.base.dark {
    background: #404041;
}

.base.dark:hover {
    background: #585859;
}

.base.outline {
    background: transparent;
    border: 1px solid white;
}

.base.outline:hover {
    text-decoration: underline;
}

.base.bright {
    background: #BCCF41;
    box-shadow: 0 3px #a6b82d;

}

.base.bright:hover {
    background: #a6b82d;
}
