.base {
    background-color: #e4f9f5;
    padding: 4rem 0;
}

.base .container {
    max-width: 1000px;
    margin: 0 auto;
}

.base h3 {
    margin-top: 2rem;
}
.base h4 {
    margin-top: 1.2rem;
}
.base section {
    margin: 3rem 0;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}



.base small {
    margin-bottom: 0.8rem;
}

.base .text {
    width: 60%;   

}

.base .text h2 {
    margin-top: 2rem;
}

.base .news-item {
    background-color: #fff;
    width: 40%;
    margin-right: 2rem;
}

.base .news-item p {
    font-size: 1.0rem;
    margin: 1rem 0 0;
}

.base .news-item > div {
    padding: 2rem;
    text-align: center;
    color: #777;
}

.base li {
    color: #777;
    margin: 1rem 0;
    line-height: 1.6rem;
}

.base button  {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-weight: bold;
    background: #1DB0D7;
    border: none;
    border-radius: 5px;
    color: white;
    padding: 10px;
    box-shadow: 0 3px #1698b4;
    transition: 100ms;
    width: 100%;
    margin-top: 1rem;
}

.base form button:hover  {
    background: #168fab;
    box-shadow: 0 0 #168fab;
    border-bottom: none;
}

.base form svg {
    font-size: 0.8rem;
    margin-left: 5px;
}

.base table {
    padding: 0.5rem;
    color: #777;
    width: 100%;
    text-align: left;
    background-color: rgba(0,0,0,0.05);
    margin: 1rem 0;
}

@media (max-width: 654px) {
    .base section {
        flex-direction: column;
        padding: 0 1rem;
    }

    .base .text, .base .news-item {
        width: 100%;
    }
}